/* global google bodymovin */
/* eslint-disable no-unused-vars */

/*
 * Main entry point for the app
 */
import * as animationConfig from '../json/cheers-animation.json'
import $ from 'jquery'

$(function () {
  // Countdown Timer
  // Event Date
  const eventDate = 'September 21 2018 18:00:00'

  // Function to retreive the remaining time
  const getRemainingTime = deadline => {
    // Get millisenconds differential between even date and current date
    // Compute the corresponding missing days, hours, minutes and seconds
    const dateDifferencial = Date.parse(deadline) - Date.parse(new Date())
    const days = Math.floor(dateDifferencial / (1000 * 60 * 60 * 24))
    const hours = Math.floor((dateDifferencial / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((dateDifferencial / 1000 / 60) % 60)
    const seconds = Math.floor((dateDifferencial / 1000) % 60)
    // Return an object with the computed data
    return {
      'differencial': dateDifferencial,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    }
  }

  // Function to initialize clock
  const initializeClock = deadline => {
    // Get correponding html element (day|hour|minutes|seconds)
    const dayElement = document.getElementById('days')
    const hourElement = document.getElementById('hours')
    const minutesElement = document.getElementById('minutes')
    const secondsElement = document.getElementById('seconds')

    // Execute function every second
    let timeInterval = setInterval(function () {
      // Get remaining time to event
      let remainingTime = getRemainingTime(deadline)
      // Change elements content with retreive remaining time
      dayElement.textContent = remainingTime.days
      hourElement.textContent = remainingTime.hours
      minutesElement.textContent = remainingTime.minutes
      secondsElement.textContent = remainingTime.seconds

      // Stop the clock if the remaining time gets to zero
      if (remainingTime.differencial <= 0) {
        clearInterval(timeInterval)
      }
    }, 1000)
  }

  initializeClock(eventDate)

  // Pop up Setup
  const modal = document.getElementById('myModal')
  const triggerPage = document.getElementById('data-protecion-page')
  const triggerSection = document.getElementById('data-protection-section')
  const close = document.getElementById('close-modal')
  const animationModal = document.getElementById('animation-model')

  // Display pop up when triggered
  triggerPage.onclick = function () {
    modal.style.display = 'block'
    $('.logo').toggleClass('logo-hidden')
  }

  triggerSection.onclick = function () {
    modal.style.display = 'block'
    $('.logo').toggleClass('logo-hidden')
  }
  // Hide pop up
  close.onclick = function () {
    modal.style.display = 'none'
    $('.logo').toggleClass('logo-hidden')
  }
  // When window is clicked, hide pop up
  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = 'none'
      $('.logo').toggleClass('logo-hidden')
    };
    if (event.target === animationModal) {
      $('.svg-container').toggleClass('show-animation')
      $('.svg-container').toggleClass('hide')
      $('#animation-model').toggleClass('animation-modal')
    }
  }

  var map

  // Map Setup
  function initMap () {
    const myLat = {lat: 40.643356, lng: -8.651676}
    map = new google.maps.Map(document.getElementById('location-image'), {
      center: myLat,
      zoom: 14,
      disableDefaultUI: true,
      styles: [
        {
          'featureType': 'all',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'simplified'
            }
          ]
        },
        {
          'featureType': 'all',
          'elementType': 'labels.text',
          'stylers': [
            {
              'color': '#444444'
            }
          ]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'simplified'
            }
          ]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'geometry',
          'stylers': [
            {
              'visibility': 'simplified'
            }
          ]
        },
        {
          'featureType': 'administrative.province',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'administrative.locality',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'simplified'
            },
            {
              'saturation': '-100'
            },
            {
              'lightness': '30'
            }
          ]
        },
        {
          'featureType': 'administrative.neighborhood',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'simplified'
            },
            {
              'gamma': '0.00'
            },
            {
              'lightness': '74'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#ffffff'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'all',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'geometry',
          'stylers': [
            {
              'visibility': 'simplified'
            },
            {
              'color': '#ff0000'
            },
            {
              'saturation': '-15'
            },
            {
              'lightness': '40'
            },
            {
              'gamma': '1.25'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'transit',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'simplified'
            }
          ]
        },
        {
          'featureType': 'transit',
          'elementType': 'labels.icon',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#ff0000'
            },
            {
              'lightness': '80'
            }
          ]
        },
        {
          'featureType': 'transit.station',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#e5e5e5'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#efefef'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        }
      ]})

    var marker = new google.maps.Marker({
      position: myLat,
      map: map,
      title: 'Epic Home'
    })
  };

  /*
   * Form Submission
  */

  // Code Access
  const accessCode = 'premieraveiro'

  // Handle Entry submission
  $('.access-form').submit((event) => {
    event.preventDefault()
    if ($('.access-input').val() === accessCode) {
      $('.access-button').trigger('click')
    } else {
      $('.access-input').val('')
      $('.access-input').attr('placeholder', 'Volte a tentar')
    }
  })

  // Handle Access Code Submission
  $('.access-button').on('click', (event) => {
    event.preventDefault()
    // When access code is correct ...
    if ($('.access-input').val() === accessCode) {
      // Hide Access Form and Show Contact Form
      $('.access-form').toggleClass('hide')
      $('.contact-form').toggleClass('hide')
      // Hide Logo for Desktop type devices
      $('.logo-container').toggleClass('logo-hidden')

      // Watch for contact input changes
      // Highlight submission button when form is filled!
      $('.contact-form').change(function () {
        if ($('.name').val() !== '' && $('.email').val() !== '' && $('#check:checked').length) {
          $('.contact-button').css('border', '2px solid #FFFFFF')
        } else {
          $('.contact-button').css('border', 'none')
        }
      })

      // Handle Contact Form Submission
      $('.contact-form').submit((event) => {
        event.preventDefault()
        let name = $('.name')
        let email = $('.email')
        let checkbox = $('#check:checked')

        // If name, email and checkbox entries are not empty ...
        if (name.val().length > 0 && email.val().length > 0 && checkbox.length > 0) {
          // Fetch Member to Mailchimp
          const $form = $('.contact-form')
          $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            error: function (error) { console.log(error) },
            success: function (data) { console.log(data.result) }
          })

          // Hide Header for mobile devices
          $('header').toggleClass('header-hidden')
          // Hide Contact Form
          $('.contact-form').toggleClass('hide')
          // Show SVG Animation
          $('.svg-container').toggleClass('hide')
          $('.svg-container').toggleClass('show-animation')
          $('.svg-container').toggleClass('show-desktop-position')
          $('#animation-model').toggleClass('animation-modal')
          // Show Location Info
          $('.location-container').toggleClass('hide')
          // Change Container Styling
          $('.counter').toggleClass('counter-invitation')
          $('.counter-container').toggleClass('counter-container-invitation')
          $('table').toggleClass('counter-table-invitation')

          // Animation configuration (validated invite)
          bodymovin.loadAnimation({
            container: document.getElementById('svg-animation'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationConfig
          })

          // Close Animation
          $('#close-animation').on('click', () => {
            $('.svg-container').toggleClass('show-animation')
            $('.svg-container').toggleClass('hide')
            $('#animation-model').toggleClass('animation-modal')
          })

          // Scroll Animation
          $('#button-container').click(() => {
            $('html, body, #right-section').animate({
              scrollTop: $('.location-moreInfo').offset().top},
            'slow')
          })

          // Call map render
          initMap()
        } else {
          // Highlight required data (name, email and checkbox)
          name.val().length === 0 ? name.attr('placeholder', 'Preencha o campo') : name.attr('placeholder', '')
          email.val().length === 0 ? email.attr('placeholder', 'Preencha o campo') : email.attr('placeholder', '')
          $('.checkbox').toggleClass('error')
        }
      })
    } else {
      $('.access-input').val('')
      $('.access-input').attr('placeholder', 'Volte a tentar')
    }
  })
})()
